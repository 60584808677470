import { useContext } from "react";
import { AppContext } from "../../../pages/root/Root";
import PropTypes from "prop-types";

import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
} from "@mui/material/";

import { Search } from "@mui/icons-material";

import { NumericInput } from "../numericInput/NumericInput";

import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers/";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { initLocalization } from "../../../utils/helpers";
import { reviewCreativeFiltersStrings } from "./locale";

import {
  MAPPING_KEYS_DATE_FORMAT,
  MAPPING_KEYS_API_DATE_FORMAT,
  MAPPING_KEYS_STATUSES,
  START_DATE,
  END_DATE,
  EXCLUDED_NUMERIC_FIELD_CHARS,
} from "../../../constants";

export const CreativeReviewFilters = ({ filters, setFilters, onSearch }) => {
  initLocalization(reviewCreativeFiltersStrings);

  const { language } = useContext(AppContext);

  const handleSetFilters = (event) => {
    setFilters((prev) => {
      let next = {
        ...prev,
        [event.target.name]: event.target.value,
      };

      if (
        event.target.name === "status" &&
        event.target.value !== MAPPING_KEYS_STATUSES.validated
      ) {
        next = { ...next, product: "" };
      }

      return next;
    });
  };

  const handleFieldKeydown = (event) => {
    if (
      event.target.name === "creativeId" &&
      EXCLUDED_NUMERIC_FIELD_CHARS.includes(event.key)
    ) {
      event.preventDefault();
    }

    if (event.key === "Enter" && event.target.value) {
      event.stopPropagation();
      onSearch();
    }
  };

  const handleDateChange = (value, name) => {
    setFilters({
      ...filters,
      [name]: dayjs(value).format(MAPPING_KEYS_API_DATE_FORMAT),
    });
  };

  return (
    <Grid container className="creative-review-filters">
      <Grid
        xs={12}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
      >
        <Box
          sx={{
            "&": { flex: 1 },
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <NumericInput
            label={reviewCreativeFiltersStrings.creativeId}
            size="small"
            name="creativeId"
            value={filters.creativeId}
            setValue={(val) =>
              handleSetFilters({ target: { name: "creativeId", value: val } })
            }
            onKeyDown={handleFieldKeydown}
          />
        </Box>

        <Box
          sx={{
            "&": { flex: 1 },
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            label={reviewCreativeFiltersStrings.creativeHashCode}
            size="small"
            name="creativeHashCode"
            value={filters.creativeHashCode}
            onChange={handleSetFilters}
            onKeyDown={handleFieldKeydown}
          />
        </Box>

        <Box
          sx={{
            "&": { flex: 1 },
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            label={reviewCreativeFiltersStrings.product}
            size="small"
            name="product"
            value={filters.product}
            onChange={handleSetFilters}
            onKeyDown={handleFieldKeydown}
            disabled={filters.status !== MAPPING_KEYS_STATUSES.validated}
          />
        </Box>

        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={language}
        >
          <DatePicker
            label={reviewCreativeFiltersStrings.startDate}
            slotProps={{ textField: { size: "small", readOnly: true } }}
            sx={{
              "&": { flex: "1" },
              "& .MuiFormLabel-root": { maxWidth: "calc(100% - 2.875rem)" },
              "& .MuiFormLabel-root.MuiFormLabel-filled, & .MuiFormLabel-root.Mui-focused":
                {
                  maxWidth: "calc(133% - 2rem)",
                },
            }}
            format={MAPPING_KEYS_DATE_FORMAT}
            value={filters[START_DATE]}
            maxDate={
              filters[END_DATE] ? dayjs(filters[END_DATE]) : dayjs(new Date())
            }
            onChange={(value) => handleDateChange(value, START_DATE)}
          />

          <DatePicker
            label={reviewCreativeFiltersStrings.endDate}
            slotProps={{ textField: { size: "small", readOnly: true } }}
            sx={{
              "&": { flex: "1" },
              "& .MuiFormLabel-root": { maxWidth: "calc(100% - 2.875rem)" },
              "& .MuiFormLabel-root.MuiFormLabel-filled, & .MuiFormLabel-root.Mui-focused":
                {
                  maxWidth: "calc(133% - 2rem)",
                },
            }}
            format={MAPPING_KEYS_DATE_FORMAT}
            value={filters[END_DATE]}
            minDate={filters[START_DATE] ? dayjs(filters[START_DATE]) : null}
            maxDate={dayjs(new Date())}
            onChange={(value) => handleDateChange(value, END_DATE)}
          />
        </LocalizationProvider>

        <Box
          sx={{
            "&": {
              flex: "1",
            },
            "& .MuiTextField-root": {
              width: "100%",
            },
          }}
        >
          <FormControl sx={{ width: "100%" }} size="small">
            <InputLabel id="status-label">
              {reviewCreativeFiltersStrings.status}
            </InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={filters.status}
              label="Status"
              onChange={handleSetFilters}
              name="status"
            >
              <MenuItem value={MAPPING_KEYS_STATUSES.validated}>
                {reviewCreativeFiltersStrings.validated}
              </MenuItem>
              <MenuItem value={MAPPING_KEYS_STATUSES.waitingProductCreated}>
                {reviewCreativeFiltersStrings.productCreated}
              </MenuItem>
              <MenuItem value={MAPPING_KEYS_STATUSES.waitingProductRequested}>
                {reviewCreativeFiltersStrings.productRequested}
              </MenuItem>
              <MenuItem value={MAPPING_KEYS_STATUSES.rejected}>
                {reviewCreativeFiltersStrings.rejected}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Button
          startIcon={<Search />}
          variant="contained"
          color="secondary"
          onClick={onSearch}
          disabled={!filters.status}
        >
          {reviewCreativeFiltersStrings.search}
        </Button>
      </Grid>
    </Grid>
  );
};

CreativeReviewFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
