import LocalizedStrings from "react-localization";

export const reviewCreativeFiltersStrings = new LocalizedStrings({
  en: {
    status: "Status",
    validated: "Validated",
    rejected: "Rejected",
    productCreated: "Waiting, product created",
    productRequested: "Waiting, product requested",
    creativeId: "Creative ID",
    creativeHashCode: "MD5 Hashcode",
    product: "Product",
    startDate: "Start date",
    endDate: "End date",
    search: "Search",
  },
  fr: {
    status: "Statut",
    validated: "Validated",
    rejected: "Rejected",
    productCreated: "Waiting, product created",
    productRequested: "Waiting, product requested",
    creativeId: "Creative ID",
    creativeHashCode: "MD5 Hashcode",
    product: "Produit",
    startDate: "Date de début",
    endDate: "Date de fin",
    search: "Recherche",
  },
});
