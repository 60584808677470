import LocalizedStrings from "react-localization";

export const globalStrings = new LocalizedStrings({
  en: {
    errors: {
      FieldRequired: "Field required",
      FieldValueNotValid: "The specified value is not valid",
      LoginFailed: "Login process failed",
      TokenCookieNotSet: "Token cookie could not be set",
      UserNotFound: "User not found",
      UserWithoutRole: "User does not have a role assigned",
      UserRoleAlreadyExists: "User role already exists",
      RoleDoesNotExist: "Role does not exist",
      UserRoleDoesNotExist: "User role does not exist",
      UserRoleAlreadyDeleted: "User role is already deleted",
    },
  },
  fr: {
    errors: {
      FieldRequired: "Champ obligatoire",
      FieldValueNotValid: "La valeur spécifiée n'est pas valide",
      LoginFailed: "Le processus de connexion a échoué",
      TokenCookieNotSet: "Le cookie n'a pas pu être défini",
      UserNotFound: "L'utilisateur n'a pas été trouvé",
      UserWithoutRole: "L'utilisateur n'a pas de rôle attribué",
      UserRoleAlreadyExists: "Le rôle de l'utilisateur existe déjà",
      RoleDoesNotExist: "Le rôle n'existe pas",
      UserRoleDoesNotExist: "Le rôle de l'utilisateur n'existe pas",
      UserRoleAlreadyDeleted: "Le rôle de l'utilisateur est déjà supprimé",
    },
  },
});
