import { useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_UPDATE_MAPPING_STATUSES } from "../../routes";
import { MAPPING_KEYS_STATUSES, MAPPING_OPTIONS } from "../../constants";

export default function useMappingKeyStatus(
  mappingOptions = MAPPING_OPTIONS.pathmatics,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(0);

  const updateMappingStatus = (payload = {}, status) => {
    setIsLoading(true);

    return Api.post(API_ROUTE_UPDATE_MAPPING_STATUSES, {
      mappingOptions,
      ...payload,
      status,
    })
      .then(() => {
        setSuccess(new Date().getTime());
        return Promise.resolve();
      })
      .catch((error) => {
        setError(error?.response?.data?.errors?.LineIds[0]);
        return Promise.reject(error?.response?.data?.errors?.LineIds[0]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const paperClipMappingKey = (payload) =>
    updateMappingStatus(payload, MAPPING_KEYS_STATUSES.waitingProductRequested);

  const rejectMappingKey = (payload) =>
    updateMappingStatus(payload, MAPPING_KEYS_STATUSES.rejected);

  const rejectBisMappingKey = (payload) =>
    updateMappingStatus(payload, MAPPING_KEYS_STATUSES.reject);

  const validateMappingKey = (payload) =>
    updateMappingStatus(payload, MAPPING_KEYS_STATUSES.validated);

  const manualDomainMappingKey = (payload) =>
    updateMappingStatus(payload, MAPPING_KEYS_STATUSES.manualDomainMapped);

  const mapToCampaignMappingKey = (payload) =>
    updateMappingStatus(payload, MAPPING_KEYS_STATUSES.manual);

  const unMapMappingKey = (payload) =>
    updateMappingStatus(payload, MAPPING_KEYS_STATUSES.notMapped);

  return {
    isLoading,
    paperClipMappingKey,
    rejectMappingKey,
    rejectBisMappingKey,
    mapToCampaignMappingKey,
    validateMappingKey,
    unMapMappingKey,
    manualDomainMappingKey,
    error,
    setError,
    success,
  };
}
