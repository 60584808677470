import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, Link } from "@mui/material";
import dayjs from "dayjs";
import { MAPPING_KEYS_DATE_FORMAT } from "../../../constants";

import { initLocalization } from "../../../utils/helpers";
import { poolMetadataStrings } from "./locale";
import "./style.scss";

export const PoolMetadata = ({ creativeDetails }) => {
  initLocalization(poolMetadataStrings);

  return (
    <Grid>
      <Typography paddingLeft="0.5rem" fontWeight="bold">
        {poolMetadataStrings.metadataTitle}:
      </Typography>
      <Grid className="pool-metadata" container flexDirection="column">
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.id}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.creativeId}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.type}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.creativeType}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.date}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {dayjs(creativeDetails.firstOccurrence).format(
              MAPPING_KEYS_DATE_FORMAT,
            )}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.campaign}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.campaignVm.campaignTitle}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.product}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.campaignVm.product.name}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.domain}
          </Typography>
          <Typography className="pool-metadata__table-content">
            <Link
              href={creativeDetails.campaignVm.advertiserDomain}
              target="_blank"
              role="link"
            >
              {creativeDetails.campaignVm.advertiserDomain}
            </Link>
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.site}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.campaignVm.advertiser}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.link}
          </Typography>
          <Typography className="pool-metadata__table-content">
            <Link
              href={creativeDetails.campaignVm.campaignUrl}
              target="_blank"
              role="link"
            >
              {poolMetadataStrings.link}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

PoolMetadata.propTypes = {
  creativeDetails: PropTypes.object.isRequired,
};
